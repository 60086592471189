import './App.css';
import React, { useState, useEffect } from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import Navigation from './components/Navigation';
import Login from './components/Login';
import Home from './pages/Home';
import Vote from './pages/Vote';
import Participants from './pages/Participants';
import Groups from './pages/Groups';
import { AppContext } from './context';
import axios from './axiosConfig';
import useDeepCompareEffect from 'use-deep-compare-effect'

export default function App() {
  const [contests, setContests] = useState([]);
  const [selectedContest, setSelectedContest] = useState({id: "", name: ""});
  const [ballotSent, setBallotSent] = useState(false);
  const [categories, setCategories] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [groups, setGroups] = useState([]);
  const [isAuth, setIsAuth] = useState(true);

  function refreshCategories() {
    axios
      .get("/categories/?contest=" + selectedContest.id)
      .then((results) => setCategories(results.data))
      .catch((error) => handleAPIError(error));
  }

  function refreshParticipants() {
    axios
      .get("/participants/?contest=" + selectedContest.id)
      .then((results) => setParticipants(results.data))
      .catch((error) => handleAPIError(error));
  }

  function refreshGroups() {
    axios
      .get("/groups/?contest=" + selectedContest.id)
      .then((results) => setGroups(results.data))
      .catch((error) => handleAPIError(error));
  }

  function refreshContests() {
    axios
      .get("/contests/?active=True")
      .then((results) => setContests(results.data))
      .catch((error) => handleAPIError(error));
  }

  function handleAPIError(error) {
    console.log(error);
    if (error.response.status === 403) {
      setIsAuth(false);
    }
  }

  function setCSRF() {
    axios
      .get('/set-csrf/')
      .catch((error) => console.log(error));
  }
  
  const context = {
    contests: contests,
    selectedContest: selectedContest,
    ballotSent: ballotSent,
    categories: categories,
    participants: participants,
    groups: groups,
    isAuth: isAuth,
    setContests: setContests,
    setSelectedContest: setSelectedContest,
    setBallotSent: setBallotSent,
    refreshCategories: refreshCategories,
    refreshParticipants: refreshParticipants,
    refreshGroups: refreshGroups,
    handleAPIError: handleAPIError,
    setIsAuth: setIsAuth
  };

  useEffect(() => {
    setCSRF();
    refreshContests();
  }, []);

  useEffect(() => {
    refreshContests();
  }, [isAuth]);

  useEffect(() => {
    if (contests.length > 0 && !contests.includes(selectedContest)) {
      setSelectedContest(contests[0]);
    }
  }, [contests, selectedContest]);

  useDeepCompareEffect(() => {
    if (contests.includes(selectedContest)) {
      refreshCategories();
      refreshGroups();
      refreshParticipants();      
    }
  }, [selectedContest]);


  return (
    <AppContext.Provider value={context}>
      <Routes>
        <Route element={
          <>
            <Navigation />
            {
              isAuth ? <Outlet /> : <Login />
            }
          </>
        }>
          <Route index element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/vote" element={<Vote />} />
          <Route path="/participants" element={<Participants />} />
          <Route path="/groups" element={<Groups />} />
        </Route>
      </Routes>
    </AppContext.Provider>
  );
}
