import { useContext, useState } from "react";
import { Container, Row, Accordion } from "react-bootstrap";
import axios from "../axiosConfig";
import { AppContext } from "../context";
import useDeepCompareEffect from 'use-deep-compare-effect';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

function Welcome() {
    return (
        <Container>
            <h3>Contest in Progress</h3>
            <br />
            <p>
                Use the Participants tab to add yourself as a participant.
            </p>
            <p>
                If you are a member of a group: <br />
                You can add yourself to a group, when entering yourself as a participant. <br />
                Use the Groups tab to register a group, if it has not already ben created.  <br />
                <em>Note: you will have to go back to the participants page, and add yourself to the group by editing your participant entry.</em>
            </p>
            <p>
                <strong>Once the voting has been finalized, the results will be displayed on this page.</strong>
            </p>
        </Container>
    );
}

function Results() {
    const context = useContext(AppContext);
    const [totals, setTotals] = useState([]);
    const [votes, setVotes] = useState([]);

    function resolveVoteName(type, id) {
        let match = { name: "" };
        if (type === "group") {
            match = context.groups.find((group) => {
                return (group.id === id)
            });
        }
        else if (type === "participant") {
            match = context.participants.find((participant) => {
                return (participant.id === id)
            });
        }

        return match.name;
    }

    function loadVotes() {
        let queryString = "";
        context.categories.forEach((category) => {
            queryString += (queryString === "" ? "?" : "&") + "category=" + category.id;
        });

        axios
            .get("/votes/" + queryString)
            .then((results) => setVotes(results.data))
            .catch((error) => context.handleAPIError(error));
    }

    function refreshTotals() {

        let tempTotals = [];
        context.categories.forEach((category) => {
            let categoryVotes = votes.filter((vote) => {
                return (vote.category === category.id)
            });

            let counts = [];
            categoryVotes.forEach((vote) => {
                let existingCountIndex = counts.findIndex((count) => {
                    return (count.id === vote[category.type])
                });

                if (existingCountIndex === -1) {
                    counts.push({
                        id: vote[category.type],
                        name: resolveVoteName(category.type, vote[category.type]),
                        voteCount: 1
                    });
                }
                else {
                    counts[existingCountIndex].voteCount++;
                }
            });

            counts.sort((a, b) => { return (b.voteCount - a.voteCount) });
            tempTotals.push({ category: category, counts: counts });
        });

        setTotals(tempTotals);
    }

    useDeepCompareEffect(() => {
        if (context.categories.length > 0 && context.participants.length > 0 && context.groups.length > 0) {
            loadVotes();
        }
    }, [context.categories, context.participants, context.groups]);

    useDeepCompareEffect(() => {
        refreshTotals();
    }, [votes]);

    return (
        <Container>
            <Row className="mb-2">
                <h3>Final Results</h3>
            </Row>
            <Accordion>
                {
                    totals.map((total) => {
                        if (total.counts.length > 0) {
                            return (
                                <Accordion.Item key={"results-" + total.category.id} eventKey={"results-" + total.category.id}>
                                    <Accordion.Header>
                                        <strong>{total.category.name}:</strong>&nbsp;{total.counts[0].name} ({total.counts[0].voteCount} Votes)
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <ResponsiveContainer width="100%" height="100" aspect={2} >
                                            <BarChart
                                                data={total.counts}
                                                margin={{ top: 5, right: 20, left: 20, bottom: 5 }}
                                                title={total.category.name}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey={"name"} angle={45} textAnchor="start" height={100} />
                                                <YAxis />
                                                <Tooltip />
                                                <Bar dataKey="voteCount" fill="#8884d8" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Accordion.Body>
                                </Accordion.Item>
                        )
                        }
                        else {
                            return (<></>)
                        }
                    })
                }
            </Accordion>
        </Container>
    );

}


export default function Home() {
    const context = useContext(AppContext);

    return (context.selectedContest.voting_state === "completed" ? <Results /> : <Welcome />);
}