import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AppContext } from '../context';
import { useContext } from 'react';

export default function Navigation() {
  const context = useContext(AppContext);

  return (
        <Navbar bg="dark" data-bs-theme="dark" className='mb-3'>
          <Container>
            <LinkContainer to="/">
              <Navbar.Brand as={NavDropdown} title={context.selectedContest.name}>
                {
                  context.contests.map((contest) => {
                    return (
                      <NavDropdown.Item key={"contest-" + contest.id} onClick={() => context.setSelectedContest(contest)}>
                        {contest.name}
                      </NavDropdown.Item>
                    )
                  })
                }
              </Navbar.Brand>
            </LinkContainer>  
            <Nav className="me-auto">
              <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/vote">
                <Nav.Link>Vote</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/participants">
                <Nav.Link>Participants</Nav.Link>
              </LinkContainer>          
              <LinkContainer to="/groups">
                <Nav.Link>Groups</Nav.Link>
              </LinkContainer>
            </Nav>
          </Container>
        </Navbar>
    );
  }