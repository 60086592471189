import { useState, useContext } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { Col, Container, Form, FormGroup, Modal, Row } from "react-bootstrap";
import { Trash, Pencil, PlusLg } from "react-bootstrap-icons";
import axios from "../axiosConfig";
import { AppContext } from "../context";


function AddModal({show, groups, onHide, onSubmit}) {
  return (
    <Modal show={show} onHide={onHide} aria-labelledby="add-modal-title" size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title id="add-modal-title">
          Add Participant
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="addForm" onSubmit={onSubmit}>
          <Form.Group className="mb-3" controlId="addFormInputName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Michael Hawk" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="addFormInputCostume">
            <Form.Label>Costume Description</Form.Label>
            <Form.Control as="textarea" placeholder="Phallus Erectus" rows={3} />
          </Form.Group>
          <Form.Group controlId={'addFormSelectGroup'}>
            <Form.Label>Group</Form.Label>
              <Form.Select aria-label='Select a group' defaultValue=''>
                <option key="default-option" value=''>No Group</option>
                {
                  groups.map((group) => {
                      return (<option key={"group-" + group.id} value={group.id}>{group.name}</option>)
                  })
                }
              </Form.Select>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" form="addForm">Submit</Button>
        <Button variant="secondary" onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function EditModal({show, groups, participant, onHide, onSubmit}) {
  return (
    <Modal show={show} onHide={onHide} aria-labelledby="edit-modal-title" size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title id="edit-modal-title">
          Edit Participant
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="editForm" onSubmit={onSubmit}>
          <FormGroup controlId="editFormParticipantId">
            <Form.Control type="hidden" value={participant.id}/>
          </FormGroup>
          <Form.Group className="mb-3" controlId="editFormInputName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" defaultValue={participant.name} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="editFormInputCostume">
            <Form.Label>Costume Description</Form.Label>
            <Form.Control as="textarea" defaultValue={participant.costume_description} rows={3} />
          </Form.Group>
          <Form.Group controlId={'editFormSelectGroup'}>
            <Form.Label>Group</Form.Label>
              <Form.Select aria-label='Select a group' defaultValue={participant.group}>
                <option key="default-option" value=''>No Group</option>
                {
                  groups.map((group) => {
                      return (<option key={"group-" + group.id} value={group.id}>{group.name}</option>)
                  })
                }
              </Form.Select>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" form="editForm">Submit</Button>
        <Button variant="secondary" onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function DeleteModal({show, groups, participant, onHide, onSubmit}) {
  return (
    <Modal show={show} onHide={onHide} aria-labelledby="delete-modal-title" size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title id="delete-modal-title">
          Remove Participant
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="deleteForm" onSubmit={onSubmit}>
          <FormGroup controlId="deleteFormParticipantId">
            <Form.Control type="hidden" value={participant.id}/>
          </FormGroup>
          <Form.Group className="mb-3" controlId="deleteFormInputName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" disabled defaultValue={participant.name} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="deleteFormInputCostume">
            <Form.Label>Costume Description</Form.Label>
            <Form.Control as="textarea" disabled defaultValue={participant.costume_description} rows={3} />
          </Form.Group>
          <Form.Group controlId={'deleteFormSelectGroup'}>
            <Form.Label>Group</Form.Label>
              <Form.Select disabled aria-label='Select a group' defaultValue={participant.group}>
                <option key="default-option" value=''>No Group</option>
                {
                  groups.map((group) => {
                      return (<option key={"group-" + group.id} value={group.id}>{group.name}</option>)
                  })
                }
              </Form.Select>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" form="deleteForm" variant="danger">Delete</Button>
        <Button variant="secondary" onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
} 

export default function Participants() {
  const context = useContext(AppContext);
  const [addModalShow, setAddModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [editingParticipant, setEditingParticipant] = useState({});

  function onAddSubmit(event) {
    event.preventDefault();
    axios
      .post('/participants/', {
        name: event.target.addFormInputName.value,
        costume_description: event.target.addFormInputCostume.value,
        group: event.target.addFormSelectGroup.value,
        contest: context.selectedContest.id
      })
      .then(() => { context.refreshParticipants() })
      .catch((error) => context.handleAPIError(error));

    setAddModalShow(false);
  }
  
  function onEditSubmit(event) {
    event.preventDefault();
    axios
      .put('/participants/' + event.target.editFormParticipantId.value + '/', {
        name: event.target.editFormInputName.value,
        costume_description: event.target.editFormInputCostume.value,
        group: event.target.editFormSelectGroup.value,
        contest: context.selectedContest.id
      })
      .then(() => { context.refreshParticipants() })
      .catch((error) => context.handleAPIError(error));
    
      setEditModalShow(false);
  }
  
  function onDeleteSubmit(event) {
    axios
    .delete('/participants/' + event.target.deleteFormParticipantId.value + '/')
    .then(() => { context.refreshParticipants() })
    .catch((error) => context.handleAPIError(error));

    setDeleteModalShow(false);
  }


  return (
    <>
      <Container>
        <Row className="mb-2">
          <Col xs={8}>
            <h3>Contest Participants</h3>
          </Col>
          <Col xs={4}>
            <Button variant="success" className="float-end" onClick={() => setAddModalShow(true)}>
              Add <PlusLg />
            </Button>
          </Col>
        </Row>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Costume Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              context.participants.map((participant) => {
                return (
                  <tr key={"participant-row-" + participant.id}>
                    <td>{participant.name}</td>
                    <td>{participant.costume_description}</td>
                    <td>
                      <Button
                        variant="secondary"
                        size="sm"
                        className="me-1" 
                        onClick={
                          () => {
                            setEditingParticipant(participant)
                            setEditModalShow(true)
                          }
                        }
                      >
                        <Pencil />
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={
                          () => {
                            setEditingParticipant(participant)
                            setDeleteModalShow(true)
                          }
                        }
                      >
                        <Trash />
                      </Button>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>

        <AddModal
          show={addModalShow}
          groups={context.groups}
          onHide={() => setAddModalShow(false)}
          onSubmit={onAddSubmit}
        />
        <EditModal
          show={editModalShow}
          groups={context.groups}
          participant={editingParticipant}
          onHide={() => setEditModalShow(false)}
          onSubmit={onEditSubmit}
        />
        <DeleteModal
          show={deleteModalShow}
          groups={context.groups}
          participant={editingParticipant}
          onHide={() => setDeleteModalShow(false)}
          onSubmit={onDeleteSubmit}
        />
      </Container>
    </>
  );
}