import React, { useState, useContext } from "react";
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import axios from '../axiosConfig';
import { AppContext } from "../context";

function Vote() {
  const context = useContext(AppContext);
  const [votes, setVotes] = useState({});

  function updateVotes(event, category) {
    let copiedVotes = Object.assign({}, votes);
    copiedVotes[category.id] = {
      "type": category.type,
      "id": event.target.value
    };
    setVotes(copiedVotes);
  }

  function onSubmit(event) {
    event.preventDefault();
    let ballot = [];

    Object.keys(votes).forEach(category => {
      let vote = votes[category];

      ballot.push({
        category: category,
        [vote.type]: vote.id
      })
    });

    axios
      .post('/votes/', ballot)
      .then(() => context.setBallotSent(true))
      .catch((error) => context.handleAPIError(error));
  }

  return (
      <Container>
        <Row className="mb-2">
          <h3>Voting Ballot</h3>
        </Row>
        <Form onSubmit={onSubmit}>
          {
            context.categories.map((category) => {
              return (
                <Row className='mb-3' key={'row-category-' + category.id}>
                  <Form.Group as={Col} controlId={'selectCategory' + category.id}>
                    <Form.Label>{category.name}</Form.Label>
                    <Form.Select
                      aria-label={category.prompt_text}
                      defaultValue='0'
                      onChange={(event) => updateVotes(event, category)}
                    >
                      <option key="default-option" value='0' disabled>{category.prompt_text}</option>
                      {
                        category.type === "participant"
                          ?
                          context.participants.map((participant) => {
                            return (<option key={"participant-" + participant.id} value={participant.id}>{participant.name}</option>)
                          })
                          :
                          context.groups.map((group) => {
                            return (<option key={"group-" + group.id} value={group.id}>{group.name}</option>)
                          })
                      }
                    </Form.Select>
                  </Form.Group>
                </Row>
              )
            })
          }
          <div className='text-center'>
            <Button variant='primary' type='submit'>
              Submit Vote!
            </Button>
          </div>
        </Form>
      </Container>
  );
}

function ThankYou() {
  return (
    <Container>
      <h3>Thank You!</h3>
      <p>Once the voting has been finalized, all results will be displayed on the home page.</p>
      <p>Good Luck!</p>
    </Container>
  )
}

function Closed() {
  return (
    <Container>
      <h3>Voting Closed</h3>
      <p>
        Voting for this contest is not yet open.<br />
        Please check back after the hosts tell you the voting process has officially begun.
      </p>
    </Container>
  )
}

function Completed() {
  return (
    <Container>
      <h3>Voting Ended</h3>
      <p>
        Voting  has already been finalized for this contest. <br />
        Results are displayed on the home page.
      </p>
    </Container>
  )
}


export default function Main() {
  const context = useContext(AppContext);
  
  if (context.selectedContest.voting_state === "closed") {
    return <Closed />
  }
  else if (context.selectedContest.voting_state === "completed") {
    return <Completed />
  }
  else {
    return context.ballotSent ? <ThankYou /> : <Vote />
  }
}