import { useState, useContext } from "react";
import axios from "../axiosConfig";
import { Container, Form, Button, Col, Row, Alert } from "react-bootstrap";
import { AppContext } from "../context";


export default function Login() {
    const context = useContext(AppContext);
    const username = 'costume_contest';
    const[errorMessage, setErrorMessage] = useState('');

    function onSubmit(event) {
        event.preventDefault();
        axios
            .post('/login/', {
                username: username,
                password: event.target.loginFormPass.value
            })
            .then(() => {
                context.setIsAuth(true);
                setErrorMessage('');
            })
            .catch((error) => {
                setErrorMessage(error.response.data.detail);
            });
    }

    return (
        <Container>
            <Row className="mb-2">
                <h3>Site Login</h3>
            </Row>

            {
                (errorMessage !== '')
                    ? <Alert variant="danger">{errorMessage}</Alert>
                    : <></>
            }

            <Form id="loginForm" onSubmit={onSubmit}>
                <Row>
                    <Col xs={6}>
                        <Form.Group className="mb-3" controlId="loginFormPass">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                        <Button type="submit">Login</Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}