import { useState, useContext } from "react";
import { Col, Container, Row, Form, FormGroup, Table, Button, Modal } from "react-bootstrap";
import { Trash, Pencil, PlusLg } from "react-bootstrap-icons";
import axios from "../axiosConfig";
import { AppContext } from "../context";

function AddModal({ show, onHide, onSubmit }) {
  return (
    <Modal show={show} onHide={onHide} aria-labelledby="add-modal-title" size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title id="add-modal-title">
          Add Group
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="addForm" onSubmit={onSubmit}>
          <Form.Group className="mb-3" controlId="addFormInputName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Juggernutz" />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" form="addForm">Submit</Button>
        <Button variant="secondary" onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function EditModal({ show, group, onHide, onSubmit }) {
  return (
    <Modal show={show} onHide={onHide} aria-labelledby="edit-modal-title" size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title id="edit-modal-title">
          Edit Group
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="editForm" onSubmit={onSubmit}>
          <FormGroup controlId="editFormGroupId">
            <Form.Control type="hidden" value={group.id} />
          </FormGroup>
          <Form.Group className="mb-3" controlId="editFormInputName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" defaultValue={group.name} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" form="editForm">Submit</Button>
        <Button variant="secondary" onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function DeleteModal({ show, group, onHide, onSubmit }) {
  return (
    <Modal show={show} onHide={onHide} aria-labelledby="delete-modal-title" size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title id="delete-modal-title">
          Remove Group
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="deleteForm" onSubmit={onSubmit}>
          <FormGroup controlId="deleteFormGroupId">
            <Form.Control type="hidden" value={group.id} />
          </FormGroup>
          <Form.Group className="mb-3" controlId="deleteFormInputName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" disabled defaultValue={group.name} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" form="deleteForm" variant="danger">Delete</Button>
        <Button variant="secondary" onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function Groups() {
  const context = useContext(AppContext);
  const [addModalShow, setAddModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [editingGroup, setEditingGroup] = useState({});

  function getParticipantNames(groupId) {
    let names = [];
    context.participants.forEach((participant) => {
      if (participant.group === groupId) {
        names.push(participant.name);
      }
    });
  
    return names.join(", ");
  }

  function onAddSubmit(event) {
    event.preventDefault();
    axios
      .post('/groups/', {
        name: event.target.addFormInputName.value,
        contest: context.selectedContest.id
      })
      .then(() => { context.refreshGroups() })
      .catch((error) => context.handleAPIError(error));

    setAddModalShow(false);
  }

  function onEditSubmit(event) {
    event.preventDefault();
    axios
      .put('/groups/' + event.target.editFormGroupId.value + '/', {
        name: event.target.editFormInputName.value,
        contest: context.selectedContest.id
      })
      .then(() => { context.refreshGroups() })
      .catch((error) => context.handleAPIError(error));

    setEditModalShow(false);
  }

  function onDeleteSubmit(event) {
    axios
      .delete('/groups/' + event.target.deleteFormGroupId.value + '/')
      .then(() => { context.refreshGroups() })
      .catch((error) => context.handleAPIError(error));

    setDeleteModalShow(false);
  }

  return (
    <>
      <Container>
        <Row className="mb-2">
          <Col xs={8}>
            <h3>Contest Groups</h3>
          </Col>
          <Col xs={4}>
            <Button variant="success" className="float-end" onClick={() => setAddModalShow(true)}>
              Add <PlusLg />
            </Button>
          </Col>
        </Row>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Participants</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              context.groups.map((group) => {
                return (
                  <tr key={"group-row-" + group.id}>
                    <td>{group.name}</td>
                    <td>{getParticipantNames(group.id)}</td>
                    <td>
                      <Button
                        variant="secondary"
                        size="sm"
                        className="me-1"
                        onClick={
                          () => {
                            setEditingGroup(group)
                            setEditModalShow(true)
                          }
                        }
                      >
                        <Pencil />
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={
                          () => {
                            setEditingGroup(group)
                            setDeleteModalShow(true)
                          }
                        }
                      >
                        <Trash />
                      </Button>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>

        <AddModal
          show={addModalShow}
          onHide={() => setAddModalShow(false)}
          onSubmit={onAddSubmit}
        />
        <EditModal
          show={editModalShow}
          group={editingGroup}
          onHide={() => setEditModalShow(false)}
          onSubmit={onEditSubmit}
        />
        <DeleteModal
          show={deleteModalShow}
          group={editingGroup}
          onHide={() => setDeleteModalShow(false)}
          onSubmit={onDeleteSubmit}
        />
      </Container>
    </>
  );
}